import styled, { css } from "styled-components"
import { BREAKPOINTS, theme } from "../utils/styled_config"

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;

  ${p =>
    p.justify &&
    css`
      justify-content: ${p.justify};
    `}
  ${p =>
    p.align &&
    css`
      align-items: ${p.align};
    `}

  ${p =>
    p.mb &&
    css`
      margin-bottom: ${`${p.mb === "auto" ? "auto" : p.mb * theme.spacer}px`};
    `}

  @media screen and (min-width: ${BREAKPOINTS.xs}) {
    ${p =>
      p.justifyXs &&
      css`
        justify-content: ${p.justifyXs};
      `}
    ${p =>
      p.alignXs &&
      css`
        justify-content: ${p.alignXs};
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.sm}) {
    ${p =>
      p.justifySm &&
      css`
        justify-content: ${p.justifySm};
      `}
    ${p =>
      p.alignSm &&
      css`
        justify-content: ${p.alignSm};
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.md}) {
    ${p =>
      p.justifyMd &&
      css`
        justify-content: ${p.justifyMd};
      `}
    ${p =>
      p.alignMd &&
      css`
        justify-content: ${p.alignMd};
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    ${p =>
      p.justifyLg &&
      css`
        justify-content: ${p.justifyLg};
      `}
    ${p =>
      p.alignLg &&
      css`
        justify-content: ${p.alignLg};
      `}

  ${p =>
      p.mbLg &&
      css`
        margin-bottom: ${`${
          p.mbLg === "auto" ? "auto" : p.mbLg * theme.spacer
        }px`};
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.xl}) {
    ${p =>
      p.justifyXl &&
      css`
        justify-content: ${p.justifyXl};
      `}
    ${p =>
      p.alignXl &&
      css`
        justify-content: ${p.alignXl};
      `}
  }
`

export default Row
