import React from "react"
import Icon from "../Icon"

const BarIcon = () => {
  return (
    <Icon viewBox="0 0 32 32">
      <path d="M4 24H28V21.3333H4V24ZM4 17.3333H28V14.6667H4V17.3333ZM4 8V10.6667H28V8H4Z" />
    </Icon>
  )
}

export default BarIcon
