const easing = [0.6, -0.05, 0.01, 1]

const duration = 1

export const STAGGER = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
}

export const FADEINUP = {
  initial: {
    y: 60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: duration,
      ease: easing,
    },
  },
  exit: {
    y: 60,
    opacity: 0,
    transition: {
      duration: duration,
      ease: easing,
    },
  },
}

export const IMAGE_REVEAL = {
  initial: {
    scaleX: 1,
  },
  animate: {
    scaleX: 0,
    transition: {
      duration: 0.6,
      scaleX: {
        delay: 0.4,
        duration: 0.3,
      },
    },
  },
}
