import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { BREAKPOINTS, theme } from "../utils/styled_config"

const MenuLink = ({ to, title, onClick }) => {
  return (
    <MenuLinkStyled to={to} onClick={onClick}>
      {title}
      <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25 15L23.59 16.41L28.17 21H12V23H28.17L23.58 27.59L25 29L32 22L25 15Z"
          fill="#C33B32"
        />
        <rect
          x="1"
          y="1"
          width="42"
          height="42"
          rx="21"
          stroke="#C33B32"
          strokeWidth="2"
        />
      </svg>
    </MenuLinkStyled>
  )
}

const MenuLinkStyled = styled(Link)`
  font-size: 32px;
  font-family: "Marcellus", serif;
  letter-spacing: -0.02em;
  line-height: 150%;
  color: #fff;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;

  svg {
    opacity: 0;
    transition: ${theme.transition};
    transform: translateX(-32px);
  }

  &:hover svg {
    opacity: 1;
    transform: translateX(16px);
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    font-size: 48px;

    svg {
      height: 44px;
    }
  }
`

export default MenuLink
