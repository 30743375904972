const white = "#FFFFFF"
const black = "#222222"
const brand = "#C33B32"
const border = "#ECEFF1"
const danger = "#C33B32"

const transition = "all 0.3s ease"

const colors = {
  brand,
  white,
  black,
  danger,
  border,
}

const breakpoints = ["321px", "576px", "768px", "992px", "1200px", "1400px"]
breakpoints.xs = breakpoints[1]
breakpoints.sm = breakpoints[2]
breakpoints.md = breakpoints[3]
breakpoints.lg = breakpoints[4]
breakpoints.xl = breakpoints[5]

const space = [
  0,
  ".25rem",
  ".5rem",
  ".75rem",
  "1rem",
  "1.25rem",
  "1.5rem",
  "1.75rem",
  "2rem",
  "2.25rem",
  "2.5rem",
  "2.75rem",
  "3rem",
  "3.25rem",
  "3.5rem",
  "3.75rem",
  "4rem",
  "4.25rem",
  "4.5rem",
  "4.75rem",
  "5rem",
]

const fontSizes = [64, 56, 48, 40, 36, 32, 24, 20, 18, 16, 14, 12]
fontSizes.d1 = fontSizes[0]
fontSizes.d2 = fontSizes[1]
fontSizes.d3 = fontSizes[2]
fontSizes.d4 = fontSizes[3]
fontSizes.h1 = fontSizes[4]
fontSizes.h2 = fontSizes[5]
fontSizes.h3 = fontSizes[6]
fontSizes.h4 = fontSizes[8]
fontSizes.h = fontSizes[9]
fontSizes.h5 = fontSizes[10]
fontSizes.h6 = fontSizes[11]

const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 700,
}

const fontStack = "Comic Sans, -apple-system, BlinkMacSystemFont, sans-serif"
const fontHeading = "Marcellus, serif"

const fontFamilies = {
  body: fontStack,
  heading: fontHeading,
}

const theme = {
  colors,
  space,
  fontSizes,
  fontWeights,
  fontFamilies,
  breakpoints,
  transition,
}

export default theme
