import React from "react"
import styled from "styled-components"
import { theme } from "../utils/styled_config"

const SeparatorStyled = styled.div`
  height: 1px;
  background: ${theme.sepator.bg};
  margin: ${props => (props.margin ? props.margin : 0)};
`

const Separator = ({ margin }) => {
  return <SeparatorStyled margin={margin} />
}

export default Separator
