import React from "react"
import Icon from "../Icon"

const CloseIcon = () => {
  return (
    <Icon viewBox="0 0 32 32">
      <path d="M25.3333 8.54675L23.4533 6.66675L16 14.1201L8.54663 6.66675L6.66663 8.54675L14.12 16.0001L6.66663 23.4534L8.54663 25.3334L16 17.8801L23.4533 25.3334L25.3333 23.4534L17.88 16.0001L25.3333 8.54675Z" />
    </Icon>
  )
}

export default CloseIcon
