import React from "react"
import styled from "styled-components"

const Icon = ({ viewBox, className, children }) => {
  return (
    <IconStyled>
      <svg
        className="icon"
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {children}
      </svg>
    </IconStyled>
  )
}

const IconStyled = styled.div`
  display: inline-flex;
  vertical-align: middle;
  line-height: 1;

  svg {
    height: 1em;
    width: 1em;
    fill: currentColor;
  }
`

export default Icon
