export const BREAKPOINTS = {
  xs: "321px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
}

export const theme = {
  spacer: 4,
  transition: "all 0.2s ease-in-out",
  sepator: {
    bg: "#424242",
  },
  color: {
    brand: "#C33B32",
    danger: "#C33B32",
  },
  heading: {
    d1: "64px",
    d2: "56px",
    d3: "48px",
    d4: "40px",
    h1: "36px",
    h2: "32px",
    h3: "24px",
    h4: "20px",
    h5: "18px",
    h6: "14px",
  },
  zIndex: {
    nav: 1000,
    menu: 1010,
    placeholder: 1020,
  },
}
