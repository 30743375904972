import styled, { css } from "styled-components"
import { BREAKPOINTS } from "../utils/styled_config"

const handleResponsiveStyles = width => {
  switch (width) {
    case "auto":
      return "flex: 0 0 auto; width: auto;"
    case 1:
      return "flex: 0 0 auto; width: 8.33333333%;"
    case 2:
      return "flex: 0 0 auto; width: 16.66666667%;"
    case 3:
      return "flex: 0 0 auto; width: 25%;"
    case 4:
      return "flex: 0 0 auto; width: 33.33333333%;"
    case 5:
      return "flex: 0 0 auto; width: 41.66666667%;"
    case 6:
      return "flex: 0 0 auto; width: 50%;"
    case 7:
      return "flex: 0 0 auto; width: 58.33333333%;"
    case 8:
      return "flex: 0 0 auto; width: 66.66666667%;"
    case 9:
      return "flex: 0 0 auto; width: 75%;"
    case 10:
      return "flex: 0 0 auto; width: 83.33333333%;"
    case 11:
      return "flex: 0 0 auto; width: 91.66666667%;"
    case 12:
      return "flex: 0 0 auto; width: 100%;"
    default:
      return "flex: 1 0 0%;"
  }
}

const Col = styled.div`
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: ${BREAKPOINTS.xs}) {
    ${p =>
      p.xs &&
      css`
        ${handleResponsiveStyles(p.xs)}
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.sm}) {
    ${p =>
      p.sm &&
      css`
        ${handleResponsiveStyles(p.sm)}
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.md}) {
    ${p =>
      p.md &&
      css`
        ${handleResponsiveStyles(p.md)}
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    ${p =>
      p.lg &&
      css`
        ${handleResponsiveStyles(p.lg)}
      `}

    ${p =>
      p.orderLg &&
      css`
        order: ${p.orderLg === "first"
          ? -1
          : p.orderLg === "last"
          ? 999999
          : p.ordeerLg};
      `}

    ${p =>
      p.hiddenMobile &&
      css`
        display: block;
      `}
  }

  @media screen and (min-width: ${BREAKPOINTS.xl}) {
    ${p =>
      p.xl &&
      css`
        ${handleResponsiveStyles(p.xl)}
      `}
  }

  ${p =>
    p.col &&
    css`
      ${handleResponsiveStyles(p.col)}
    `}

  ${p =>
    p.hiddenMobile &&
    css`
      display: none;
    `}
`

export default Col
