import React from "react"
import Icon from "../Icon"

const FacebookIcon = () => {
  return (
    <Icon viewBox="0 0 24 25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.725 22.3906V14.6456H18.325L18.714 11.6276H15.724V9.70063C15.724 8.82663 15.967 8.23063 17.221 8.23063H18.819V5.53062C18.0451 5.44827 17.2673 5.40821 16.489 5.41062C14.185 5.41062 12.608 6.81763 12.608 9.40063V11.6276H10V14.6456H12.607V22.3906H3.104C2.494 22.3906 2 21.8966 2 21.2866V3.49463C2 2.88463 2.494 2.39062 3.104 2.39062H20.896C21.506 2.39062 22 2.88463 22 3.49463V21.2866C22 21.8966 21.506 22.3906 20.896 22.3906H15.725Z"
        fill="currentColor"
      />
    </Icon>
  )
}

export default FacebookIcon
