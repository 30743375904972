import styled from "styled-components"
import { BREAKPOINTS } from "../utils/styled_config"

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: ${BREAKPOINTS.sm}) {
    width: 540px;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}) {
    width: 720px;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    width: 960px;
  }

  @media screen and (min-width: ${BREAKPOINTS.xl}) {
    width: 1140px;
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}) {
    width: 1320px;
  }
`

export default Container
