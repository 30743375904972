import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { BREAKPOINTS, theme } from "../utils/styled_config"
import { FADEINUP } from "../utils/animation"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { FACEBOOK_URL, INSTAGRAM_URL, YOUTUBE_URL } from "../utils/links"

import Container from "./Container"
import Col from "./Col"
import Row from "./Row"
import LogoVector from "./LogoVector"
import MenuLink from "./MenuLink"
import BarIcon from "./Icons/BarIcon"
import CloseIcon from "./Icons/CloseIcon"
import Separator from "./Separator"
import FacebookIcon from "../components/Icons/FacebookIcon"
import LinkedinIcon from "../components/Icons/LinkedinIcon"
import InstagramIcon from "./Icons/InstagramIcon"

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const [menuBg, setMenuBg] = useState(false)

  const handleScroll = () => {
    if (document.body.scrollTop > 150) {
      setMenuBg(true)
    } else {
      setMenuBg(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener("scroll", handleScroll)
    return () => document.body.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <>
      <Header bg={menuBg}>
        <Container>
          <Row justify="space-between" align="center">
            <Col col="auto">
              <LogoLink to="/" aria-label="go to homepage">
                <LogoVector />
              </LogoLink>
            </Col>
            <Col col="auto">
              <NavigationToggle
                onClick={() => setMenuOpen(true)}
                aria-label="open menu"
              >
                <BarIcon />
              </NavigationToggle>
            </Col>
          </Row>
        </Container>
      </Header>

      <Menu
        className={menuOpen && "open"}
        initial="initial"
        animate={menuOpen ? "animate" : "exit"}
        variants={menuVariant}
      >
        <ContainerHeader>
          <Row justify="space-between" align="center">
            <Col col="auto">
              <LogoLink to="/">
                <LogoVector />
              </LogoLink>
            </Col>
            <Col col="auto">
              <NavigationToggle
                onClick={() => setMenuOpen(false)}
                aria-label="close menu"
              >
                <CloseIcon />
              </NavigationToggle>
            </Col>
          </Row>
        </ContainerHeader>

        <Container>
          <MenuList>
            <motion.li variants={FADEINUP}>
              <MenuLink
                to="/portfolio"
                title="Portfolio"
                onClick={() => setMenuOpen(false)}
              />
            </motion.li>
            <motion.li variants={FADEINUP}>
              <MenuWrap>
                <MenuLink
                  to="/our-story"
                  title="Our Story"
                  onClick={() => setMenuOpen(false)}
                />
                <div>
                  <MenuLink
                    to="/sustainable-tourism"
                    title="SUSTAINABLE TOURISM"
                    onClick={() => setMenuOpen(false)}
                  />
                </div>
              </MenuWrap>
            </motion.li>
            <motion.li variants={FADEINUP}>
              <MenuLink
                to="/services"
                title="Services"
                onClick={() => setMenuOpen(false)}
              />
            </motion.li>
            <motion.li variants={FADEINUP}>
              <MenuWrap>
                <MenuLink
                  to="/news"
                  title="News"
                  onClick={() => setMenuOpen(false)}
                />
                <div>
                  <MenuLink
                    to="/news"
                    title="PRESS"
                    onClick={() => setMenuOpen(false)}
                  />
                  <MenuLink
                    to="/insider"
                    title="INSIDER"
                    onClick={() => setMenuOpen(false)}
                  />
                </div>
              </MenuWrap>
            </motion.li>
            <motion.li variants={FADEINUP}>
              <MenuWrap>
                <MenuLink
                  to="/contact-us"
                  title="Talk To Us"
                  onClick={() => setMenuOpen(false)}
                />
                <div>
                  <MenuLink
                    to="/careers"
                    title="CAREERS"
                    onClick={() => setMenuOpen(false)}
                  />
                </div>
              </MenuWrap>
            </motion.li>
          </MenuList>
          <motion.div variants={FADEINUP}>
            <Separator margin="24px 0;" />
          </motion.div>
          <Row align="start" justify="center">
            <Col lg variants={FADEINUP} as={motion.div}>
              TAJARA Leisure & Hospitality Group, Inc. All rights reserved. ©{" "}
              {new Date().getFullYear()}
              <br />
              <br />
            </Col>
            <Col lg variants={FADEINUP} as={motion.div}>
              <FooterLinkList>
                <FooterLinkSocial
                  href="https://www.facebook.com/tajarahospitality/"
                  target="_blank"
                >
                  <FacebookIcon />
                </FooterLinkSocial>
                <FooterLinkSocial
                  href="https://www.linkedin.com/mwlite/in/cyndy-tan-jarabata-53504750"
                  target="_blank"
                >
                  <LinkedinIcon />
                </FooterLinkSocial>
                <FooterLinkSocial
                  href="https://www.instagram.com/tajara.hospitality"
                  target="_blank"
                >
                  <InstagramIcon />
                </FooterLinkSocial>
              </FooterLinkList>
            </Col>
          </Row>
        </Container>
      </Menu>
    </>
  )
}

const FooterLinkList = styled.li`
  display: flex;
  align-items: center;
  @media screen and (min-width: ${BREAKPOINTS.md}) {
    margin-right: 20px;
    justify-content: end;
    a&:last-of-type {
      margin-right: 0;
    }
  }
`

const FooterLink = styled.a`
  text-transform: uppercase;
  color: white;
  display: inline-flex;
  color: #fff;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.04em;
  transition: ${theme.transition};

  &:hover {
    color: #aaa;
  }
`

const FooterLinkSocial = styled(FooterLink)`
  margin-right: 10px;
  font-size: 20px;
  svg {
    width: 1em;
    height: 1em;
  }
  &:last-of-type {
    margin-right: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    min-height: auto;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

const Header = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${theme.zIndex.nav};
  padding: 12px 0;
  transition: ${theme.transition};
  background: ${props => (props.bg ? "rgba(0, 0, 0, .7)" : "none")};
`

const LogoLink = styled(Link)`
  display: inline-block;
  svg {
    color: #fff;
  }
  &:hover svg {
    opacity: 1;
    color: #c33b32;
  }

  svg {
    height: 32px;
    transition: ${theme.transition};
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    svg {
      height: 55px;
    }
  }
`

const Menu = styled(motion.div)`
  position: fixed;
  background: #000;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  overflow: auto;
  z-index: ${theme.zIndex.menu};
`

const NavigationToggle = styled.button`
  height: 48px;
  width: 48px;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  background: transparent;
  border-radius: 100%;
  font-size: 24px;
  transition: ${theme.transition};
  background: rgba(0, 0, 0, 0.2);

  &:hover {
    color: #fff;
  }
`

const SocialMediaList = styled.ul`
  list-style: none;
  font-size: 24px;
  margin: 0;
  padding: 0;
  li {
    display: inline-block;

    & + li {
      margin-left: 18px;
    }
  }

  a {
    color: #fff;

    &:hover {
      color: #ddd;
    }
  }
`

const ContainerHeader = styled(Container)`
  padding-top: 12px;
  padding-bottom: 12px;
`

const MenuList = styled.ul`
  margin: 0;
  margin-top: 84px;
  li + li {
    margin-top: 18px;
  }
`

const MenuWrap = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.md}) {
    display: flex;
    align-items: center;
  }
  > a {
    @media screen and (min-width: ${BREAKPOINTS.md}) {
      margin-right: 20px;
      &:hover {
        color: #c33b32;
      }
      svg {
        display: none;
      }
    }
  }
  div {
    @media screen and (max-width: ${BREAKPOINTS.md}) {
      padding-left: 24px;
      margin-top: 8px;
    }
    a {
      @media screen and (max-width: ${BREAKPOINTS.md}) {
        font-size: 16px;
        display: block;
        svg {
          display: none;
        }
      }
      @media screen and (min-width: ${BREAKPOINTS.md}) {
        font-size: 16px;
        &:not(:last-of-type) {
          margin-right: 20px;
        }
        svg {
          display: none;
        }
        &:hover {
          color: #c33b32;
          svg {
            display: none;
          }
        }
      }
      + a {
        @media screen and (max-width: ${BREAKPOINTS.md}) {
          margin-top: 8px;
        }
      }
    }
  }
`

const menuVariant = {
  initial: {
    height: 0,
  },
  animate: {
    height: "100%",
    transition: {
      duration: 0.4,
      staggerChildren: 0.05,
      delayChildren: 0.2,
    },
  },
  exit: {
    height: 0,
    transition: {
      duration: 0.3,
      staggerChildren: 0.05,
      staggerDirection: -1,
      delay: 0.5,
    },
  },
}

export default Navigation
