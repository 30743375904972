import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import theme from "../utils/theme"

import "./layout.css"
import "../stylesheets/slick.css"
import "../stylesheets/slick-theme.css"
import "../stylesheets/modal.css"
import "../stylesheets/accordion.css"
import { motion, AnimatePresence } from "framer-motion"
const Layout = ({ children }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const url = typeof window !== "undefined" ? window.location.href : ""

  useEffect(() => {
    setTimeout(() => {
      document.body.scrollTo(0, 0)
    }, 300)
  }, [url])

  return (
    <ThemeProvider theme={theme}>
      <AnimatePresence exitBeforeEnter>
        {mounted && (
          <motion.main
            key={url}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
                ease: "easeInOut",
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.3,
                ease: "easeInOut",
              },
            }}
          >
            {children}
          </motion.main>
        )}
      </AnimatePresence>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
