import React from "react"
import Layout from "./src/components/Layout"
import Navigation from "./src/components/Navigation"
import Footer from "./src/components/Footer"
import Loader from "./src/components/Loader"

export const wrapPageElement = ({ element, props }) => (
  <>
    <Loader />
    <Navigation />
    <Layout {...props}>{element}</Layout>
    <Footer />
  </>
)
