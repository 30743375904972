import React from "react"

const LogoVector = () => {
  return (
    <svg viewBox="0 0 251 88" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.2545 6.27134H0.5V9.73428e-06H40.9202V6.27134H24.1657V50.459H17.2545V6.27134Z"
        fill="currentColor"
      />
      <path
        d="M88.4048 51.0356C85.4727 51.0356 82.7501 50.4109 80.237 49.1615C77.7238 47.8639 75.6993 46.0618 74.1635 43.7551L78.2125 38.8534C80.9118 42.8421 84.3092 44.8364 88.4048 44.8364C91.1507 44.8364 93.2217 43.9714 94.6179 42.2414C96.0607 40.5113 96.782 37.9644 96.782 34.6004V9.73428e-06H103.693V34.24C103.693 39.8145 102.39 44.0195 99.7839 46.8548C97.2242 49.642 93.4311 51.0356 88.4048 51.0356Z"
        fill="currentColor"
      />
      <path
        d="M202.974 50.459L192.433 34.9609C191.13 35.057 190.106 35.105 189.361 35.105H177.284V50.459H170.303V9.73428e-06H189.361C195.691 9.73428e-06 200.67 1.56183 204.301 4.68548C207.931 7.80913 209.746 12.1102 209.746 17.5886C209.746 21.4811 208.815 24.797 206.953 27.5362C205.092 30.2754 202.439 32.2697 198.995 33.5192L210.583 50.459H202.974ZM189.152 28.9779C193.573 28.9779 196.947 27.9927 199.274 26.0224C201.601 24.0521 202.765 21.2408 202.765 17.5886C202.765 13.9363 201.601 11.149 199.274 9.22679C196.947 7.25649 193.573 6.27134 189.152 6.27134H177.284V28.9779H189.152Z"
        fill="currentColor"
      />
      <path
        d="M243.81 50.459H251L228.87 0H221.959L234.635 28.9779H221.959V34.9609H237.212L243.81 50.459Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.029 34.9609L117.432 50.459H110.242L132.372 9.73428e-06H139.283L161.483 50.459H154.153L147.556 34.9609H124.029ZM135.793 7.3526L145.012 28.9779H126.573L135.793 7.3526Z"
        fill="currentColor"
      />
      <path
        d="M45.7941 50.459H38.6037L60.7336 0H67.6448L54.9688 28.9779H67.6448V34.9609H52.3912L45.7941 50.459Z"
        fill="currentColor"
      />
      <path
        d="M78.8819 67.6254V83.3342H76.6378V76.3326H67.6164V83.3342H65.3723V67.6254H67.6164V74.3802H76.6378V67.6254H78.8819Z"
        fill="currentColor"
      />
      <path
        d="M87.3124 83.4689C86.1305 83.4689 85.0683 83.2071 84.1257 82.6835C83.1832 82.1598 82.4426 81.4417 81.904 80.5291C81.3804 79.6015 81.1186 78.5543 81.1186 77.3873C81.1186 76.2204 81.3804 75.1806 81.904 74.268C82.4426 73.3404 83.1832 72.6223 84.1257 72.1136C85.0683 71.59 86.1305 71.3282 87.3124 71.3282C88.4943 71.3282 89.549 71.59 90.4766 72.1136C91.4191 72.6223 92.1522 73.3404 92.6758 74.268C93.2144 75.1806 93.4837 76.2204 93.4837 77.3873C93.4837 78.5543 93.2144 79.6015 92.6758 80.5291C92.1522 81.4417 91.4191 82.1598 90.4766 82.6835C89.549 83.2071 88.4943 83.4689 87.3124 83.4689ZM87.3124 81.5838C88.0754 81.5838 88.7561 81.4118 89.3545 81.0677C89.9679 80.7086 90.4467 80.2149 90.7908 79.5866C91.1349 78.9432 91.3069 78.2102 91.3069 77.3873C91.3069 76.5645 91.1349 75.8389 90.7908 75.2105C90.4467 74.5672 89.9679 74.0735 89.3545 73.7294C88.7561 73.3853 88.0754 73.2133 87.3124 73.2133C86.5494 73.2133 85.8612 73.3853 85.2478 73.7294C84.6494 74.0735 84.1706 74.5672 83.8116 75.2105C83.4675 75.8389 83.2954 76.5645 83.2954 77.3873C83.2954 78.2102 83.4675 78.9432 83.8116 79.5866C84.1706 80.2149 84.6494 80.7086 85.2478 81.0677C85.8612 81.4118 86.5494 81.5838 87.3124 81.5838Z"
        fill="currentColor"
      />
      <path
        d="M100.099 83.4689C99.1262 83.4689 98.1911 83.3417 97.2935 83.0874C96.3958 82.8181 95.6927 82.4815 95.184 82.0775L96.0816 80.372C96.6053 80.746 97.2411 81.0452 97.9891 81.2697C98.7372 81.4941 99.4927 81.6063 100.256 81.6063C102.141 81.6063 103.083 81.0677 103.083 79.9905C103.083 79.6314 102.956 79.3472 102.702 79.1377C102.447 78.9283 102.126 78.7787 101.737 78.6889C101.363 78.5842 100.824 78.472 100.121 78.3523C99.1636 78.2027 98.3781 78.0306 97.7647 77.8362C97.1663 77.6417 96.6501 77.3125 96.2163 76.8487C95.7824 76.385 95.5655 75.7342 95.5655 74.8964C95.5655 73.8192 96.0143 72.9589 96.912 72.3156C97.8096 71.6573 99.014 71.3282 100.525 71.3282C101.318 71.3282 102.111 71.4254 102.904 71.6199C103.697 71.8144 104.347 72.0762 104.856 72.4054L103.936 74.1109C102.964 73.4826 101.819 73.1684 100.503 73.1684C99.5899 73.1684 98.8943 73.318 98.4155 73.6172C97.9368 73.9164 97.6974 74.3129 97.6974 74.8066C97.6974 75.1956 97.832 75.5023 98.1013 75.7267C98.3706 75.9511 98.6998 76.1157 99.0888 76.2204C99.4927 76.3251 100.054 76.4448 100.772 76.5794C101.729 76.744 102.5 76.9235 103.083 77.118C103.682 77.2976 104.19 77.6117 104.609 78.0606C105.028 78.5094 105.238 79.1377 105.238 79.9456C105.238 81.0228 104.774 81.883 103.846 82.5264C102.934 83.1547 101.684 83.4689 100.099 83.4689Z"
        fill="currentColor"
      />
      <path
        d="M114.46 71.3282C115.612 71.3282 116.645 71.5825 117.557 72.0912C118.47 72.5999 119.181 73.3105 119.689 74.2231C120.213 75.1357 120.475 76.1905 120.475 77.3873C120.475 78.5842 120.213 79.6464 119.689 80.574C119.181 81.4866 118.47 82.1972 117.557 82.7059C116.645 83.2146 115.612 83.4689 114.46 83.4689C113.608 83.4689 112.822 83.3043 112.104 82.9752C111.401 82.6461 110.803 82.1673 110.309 81.5389V87.6878H108.154V71.4404H110.219V73.3255C110.698 72.6672 111.304 72.1735 112.037 71.8443C112.77 71.5002 113.578 71.3282 114.46 71.3282ZM114.281 81.5838C115.044 81.5838 115.725 81.4118 116.323 81.0677C116.936 80.7086 117.415 80.2149 117.759 79.5866C118.118 78.9432 118.298 78.2102 118.298 77.3873C118.298 76.5645 118.118 75.8389 117.759 75.2105C117.415 74.5672 116.936 74.0735 116.323 73.7294C115.725 73.3853 115.044 73.2133 114.281 73.2133C113.533 73.2133 112.852 73.3928 112.239 73.7519C111.64 74.0959 111.162 74.5822 110.803 75.2105C110.458 75.8389 110.286 76.5645 110.286 77.3873C110.286 78.2102 110.458 78.9432 110.803 79.5866C111.147 80.2149 111.625 80.7086 112.239 81.0677C112.852 81.4118 113.533 81.5838 114.281 81.5838Z"
        fill="currentColor"
      />
      <path
        d="M123.676 71.4404H125.83V83.3342H123.676V71.4404ZM124.753 69.1514C124.334 69.1514 123.982 69.0168 123.698 68.7475C123.429 68.4782 123.294 68.149 123.294 67.76C123.294 67.3711 123.429 67.0419 123.698 66.7726C123.982 66.4884 124.334 66.3463 124.753 66.3463C125.172 66.3463 125.516 66.4809 125.785 66.7502C126.069 67.0045 126.212 67.3262 126.212 67.7152C126.212 68.1191 126.069 68.4632 125.785 68.7475C125.516 69.0168 125.172 69.1514 124.753 69.1514Z"
        fill="currentColor"
      />
      <path
        d="M136.855 82.6386C136.541 82.9079 136.152 83.1173 135.688 83.2669C135.225 83.4016 134.746 83.4689 134.252 83.4689C133.055 83.4689 132.128 83.1472 131.469 82.5039C130.811 81.8606 130.482 80.9405 130.482 79.7437V73.2133H128.462V71.4404H130.482V68.8372H132.636V71.4404H136.047V73.2133H132.636V79.6539C132.636 80.2972 132.794 80.7909 133.108 81.135C133.437 81.4791 133.901 81.6512 134.499 81.6512C135.157 81.6512 135.718 81.4641 136.182 81.0901L136.855 82.6386Z"
        fill="currentColor"
      />
      <path
        d="M144.016 71.3282C145.661 71.3282 146.918 71.7321 147.786 72.54C148.668 73.3479 149.11 74.5523 149.11 76.1531V83.3342H147.068V81.7634C146.708 82.3169 146.192 82.7433 145.519 83.0425C144.861 83.3268 144.075 83.4689 143.163 83.4689C141.831 83.4689 140.762 83.1472 139.954 82.5039C139.161 81.8606 138.764 81.0153 138.764 79.9681C138.764 78.9208 139.146 78.083 139.909 77.4547C140.672 76.8113 141.884 76.4897 143.544 76.4897H146.955V76.0633C146.955 75.1357 146.686 74.4251 146.147 73.9314C145.609 73.4377 144.816 73.1908 143.769 73.1908C143.066 73.1908 142.377 73.3105 141.704 73.5499C141.031 73.7743 140.462 74.081 139.999 74.47L139.101 72.8542C139.714 72.3605 140.447 71.9865 141.3 71.7321C142.153 71.4628 143.058 71.3282 144.016 71.3282ZM143.522 81.8082C144.345 81.8082 145.055 81.6287 145.654 81.2697C146.252 80.8956 146.686 80.372 146.955 79.6988V78.0381H143.634C141.809 78.0381 140.896 78.6515 140.896 79.8783C140.896 80.4767 141.128 80.948 141.592 81.2921C142.056 81.6362 142.699 81.8082 143.522 81.8082Z"
        fill="currentColor"
      />
      <path
        d="M153.321 66.6829H155.475V83.3342H153.321V66.6829Z"
        fill="currentColor"
      />
      <path
        d="M159.813 71.4404H161.967V83.3342H159.813V71.4404ZM160.89 69.1514C160.471 69.1514 160.12 69.0168 159.836 68.7475C159.566 68.4782 159.432 68.149 159.432 67.76C159.432 67.3711 159.566 67.0419 159.836 66.7726C160.12 66.4884 160.471 66.3463 160.89 66.3463C161.309 66.3463 161.653 66.4809 161.923 66.7502C162.207 67.0045 162.349 67.3262 162.349 67.7152C162.349 68.1191 162.207 68.4632 161.923 68.7475C161.653 69.0168 161.309 69.1514 160.89 69.1514Z"
        fill="currentColor"
      />
      <path
        d="M172.993 82.6386C172.679 82.9079 172.29 83.1173 171.826 83.2669C171.362 83.4016 170.883 83.4689 170.39 83.4689C169.193 83.4689 168.265 83.1472 167.607 82.5039C166.949 81.8606 166.619 80.9405 166.619 79.7437V73.2133H164.6V71.4404H166.619V68.8372H168.774V71.4404H172.185V73.2133H168.774V79.6539C168.774 80.2972 168.931 80.7909 169.245 81.135C169.574 81.4791 170.038 81.6512 170.636 81.6512C171.295 81.6512 171.856 81.4641 172.319 81.0901L172.993 82.6386Z"
        fill="currentColor"
      />
      <path
        d="M186.128 71.4404L180.36 84.5236C179.851 85.7355 179.253 86.5882 178.565 87.0819C177.892 87.5756 177.076 87.8225 176.119 87.8225C175.535 87.8225 174.967 87.7252 174.413 87.5308C173.875 87.3512 173.426 87.0819 173.067 86.7229L173.987 85.1071C174.6 85.6906 175.311 85.9823 176.119 85.9823C176.642 85.9823 177.076 85.8402 177.42 85.5559C177.779 85.2866 178.101 84.8154 178.385 84.1421L178.767 83.3118L173.516 71.4404H175.76L179.911 80.9555L184.018 71.4404H186.128Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LogoVector
