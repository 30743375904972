import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { Link } from "gatsby"
import { theme, BREAKPOINTS } from "../utils/styled_config"

import Container from "./Container"
import Row from "./Row"
import Col from "./Col"
import Separator from "./Separator"
import LogoVector from "./LogoVector"
import FacebookIcon from "./Icons/FacebookIcon"
import LinkedinIcon from "./Icons/LinkedinIcon"
import InstagramIcon from "./Icons/InstagramIcon"

const GlobalStyle = createGlobalStyle`
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    .hiddenMobile {
      display: none;
    }
  };
  @media screen and (min-width: ${BREAKPOINTS.md}) {
    .hiddenWeb {
      display: none;
    }
  };
`

const Footer = () => {
  return (
    <FooterStyled>
      <GlobalStyle />
      <Container>
        <Row justify="center" justifyLg="space-between">
          <Col lg="auto">
            <LogoStyle>
              <LogoVector />
            </LogoStyle>
          </Col>
          <Col col="auto" hiddenMobile>
            <ul>
              <li>
                <FooterLink to="/our-story">Our Story</FooterLink>
              </li>
              <li>
                <FooterLink to="/portfolio">Portfolio</FooterLink>
              </li>
              <li>
                <FooterLink to="/services">Services</FooterLink>
              </li>
            </ul>
          </Col>
          <Col col="auto" hiddenMobile>
            <ul>
              <li>
                <FooterLink to="/insider">7107 Insider</FooterLink>
              </li>
              <li>
                <FooterLink to="/news">News</FooterLink>
              </li>
              <li>
                <FooterLink to="/careers">Careers</FooterLink>
              </li>
            </ul>
          </Col>
          <Col col="auto" hiddenMobile>
            <ul>
              <li>
                <FooterLink to="/contact-us">Contact Us</FooterLink>
              </li>
              <FooterLinkList>
                <FooterLinkSocialAnchor
                  href="https://www.facebook.com/tajarahospitality/"
                  target="_blank"
                >
                  <FacebookIcon />
                </FooterLinkSocialAnchor>
                <FooterLinkSocialAnchor
                  href="https://www.linkedin.com/mwlite/in/cyndy-tan-jarabata-53504750"
                  target="_blank"
                >
                  <LinkedinIcon />
                </FooterLinkSocialAnchor>
                <FooterLinkSocialAnchor
                  href="https://www.instagram.com/tajara.hospitality"
                  target="_blank"
                >
                  <InstagramIcon />
                </FooterLinkSocialAnchor>
              </FooterLinkList>
            </ul>
          </Col>
        </Row>
        <div className="hiddenMobile">
          <Separator margin="24px 0;" />
        </div>
        <RowStyled align="center" justify="center">
          <ColStyled col="auto" className="hiddenWeb">
            <UlStyled>
              <FooterLinkList>
                <FooterLinkSocialAnchor
                  href="https://www.facebook.com/tajarahospitality/"
                  target="_blank"
                >
                  <FacebookIcon />
                </FooterLinkSocialAnchor>
                <FooterLinkSocialAnchor
                  href="https://www.linkedin.com/mwlite/in/cyndy-tan-jarabata-53504750"
                  target="_blank"
                >
                  <LinkedinIcon />
                </FooterLinkSocialAnchor>
              </FooterLinkList>
            </UlStyled>
          </ColStyled>
          <ColStyled lg>
            TAJARA Leisure & Hospitality Group, Inc. All
            <br className="hiddenWeb" /> rights reserved. ©{" "}
            {new Date().getFullYear()}
          </ColStyled>
          <ColOrder col="auto">
            <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
          </ColOrder>
          <Col col="auto">
            <FooterLink to="/terms-and-conditions">
              Terms & Conditions
            </FooterLink>
          </Col>
        </RowStyled>
      </Container>
    </FooterStyled>
  )
}

const FooterStyled = styled.footer`
  padding: 24px 0;
  background: #000;
  font-size: 14px;
  border-top: 1px solid ${theme.sepator.bg};
  text-align: center;
  z-index: 99;
  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    padding: 80px 0;
    text-align: left;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    padding: 35px 0;
  } ;
`

const LogoStyle = styled.div`
  svg {
    height: 50px;
    margin-bottom: 16px;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    svg {
      height: 87px;
    }
  }
`

const FooterLink = styled(Link)`
  text-transform: uppercase;
  color: white;
  display: inline-flex;
  min-height: 40px;
  color: #fff;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.04em;
  transition: ${theme.transition};

  &:hover {
    color: #aaa;
  }
`

const FooterLinkAnchor = styled.a`
  text-transform: uppercase;
  color: white;
  display: inline-flex;
  min-height: 40px;
  color: #fff;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.04em;
  transition: ${theme.transition};

  &:hover {
    color: #aaa;
  }
`

const FooterLinkSocial = styled(FooterLink)`
  margin-right: 10px;
  font-size: 24px;

  svg {
    width: 1em;
    height: 1em;

    rect {
      width: 1em;
      height: 1em;
    }
  }
  &:last-of-type {
    margin-right: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    min-height: auto;
    &:last-of-type {
      margin-right: 0;
    }
  }
`
const FooterLinkSocialAnchor = styled(FooterLinkAnchor)`
  margin-right: 10px;
  font-size: 24px;

  svg {
    width: 1em;
    height: 1em;

    rect {
      width: 0.8em;
      height: 0.8em;
    }
  }
  &:last-of-type {
    margin-right: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    min-height: auto;
    &:last-of-type {
      margin-right: 0;
    }
  }
`

const FooterLinkList = styled.li`
  display: flex;
  align-items: center;
  @media screen and (min-width: ${BREAKPOINTS.md}) {
    margin-right: 20px;
    a&:last-of-type {
      margin-right: 0;
    }
  }
`

const ColStyled = styled(Col)`
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    margin-top: 20px;
  } ;
`
const ColOrder = styled(Col)`
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    margin-top: 20px;
    order: 1;
  } ;
`

const RowStyled = styled(Row)`
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    flex-direction: column-reverse;
  } ;
`

const UlStyled = styled.ul`
  @media screen and (max-width: ${BREAKPOINTS.md}) {
    margin: 0;
  } ;
`

export default Footer
